
import { WorkOrderStatusConfigEntityModel, WorkOrderStatusConfigQueryModel } from '@common-src/entity-model/work-order-status-config-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, del } from './request';

const URL_PATH = `${WORKORDER_BASE_REQUEST_PATH}/process/definition/status`;

class WorkOrderStatusConfigService implements ICRUDQ<WorkOrderStatusConfigEntityModel, WorkOrderStatusConfigQueryModel> {
    async create(model: WorkOrderStatusConfigEntityModel):Promise<WorkOrderStatusConfigEntityModel> {
        const url = `${URL_PATH}/processStatus/add/${model.processKey}`;
        // const params = model.toService();
        const res = await post(url);
        return res;
    }

    async retrieve(modelId: string):Promise<WorkOrderStatusConfigEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new WorkOrderStatusConfigEntityModel().toModel(res);
    }

    async update(model: WorkOrderStatusConfigEntityModel):Promise<WorkOrderStatusConfigEntityModel> {
        const url = `${URL_PATH}/flowNodeStatus/save/${model.id}`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: WorkOrderStatusConfigEntityModel):Promise<WorkOrderStatusConfigEntityModel> {
        const url = `${URL_PATH}/processStatus/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: WorkOrderStatusConfigQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/processStatus/list/treeify`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new WorkOrderStatusConfigEntityModel().toModel(item));
        return res;
    }

    async updateStatus(model: WorkOrderStatusConfigEntityModel):Promise<WorkOrderStatusConfigEntityModel> {
        const url = `${URL_PATH}/processStatus/update/${model.id}`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }
}

export default new WorkOrderStatusConfigService();
